import React, { useEffect } from 'react';

const GoogleTranslateWidget = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: 'ja',
        includedLanguages: 'ar,bn,de,en,es,fr,hi,it,ja,jv,ko,nl,pt,ru,th,vi,zh-CN',
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        multilanguagePage: true
      }, 'google_translate_element');
    };
  }, []);

  return (
    <div id="google_translate_element"></div>
  );
};

export default GoogleTranslateWidget;