import React from "react"

export default () => (
  <footer>
    <div className="content">
      <a href={`${process.env.MODD_CART_URL}help/help.aspx?ccode=${process.env.MODD_CART_ID}`}>ご利用ガイド</a>
      <a href={`${process.env.MODD_CART_URL}help/kiyaku.aspx?ccode=${process.env.MODD_CART_ID}`}>利用規約</a>
      <a href={`${process.env.MODD_CART_URL}help/privacy.aspx?ccode=${process.env.MODD_CART_ID}`}>プライバシーポリシー</a>
      <a href={`https://inquiry.kerorofc.com/form/`}>お問い合わせ</a>
      <a href={`${process.env.MODD_CART_URL}help/tokutei.aspx?ccode=${process.env.MODD_CART_ID}`}>特定商取引法表記</a>
    </div>
    <div className="copy">(C)吉崎観音／KADOKAWA・BNP・テレビ東京・NAS・BV</div>
  </footer>
)
