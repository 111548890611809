import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
//import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({title, description}) => {
  //const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={title ? titleTemplate : null}
    >
      <html lang="ja"/>
      <meta charSet="utf-8"/>
      <meta name="description" content={cleanHtmlTag(seo.description)}/>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
      }
    }
  }
`

const cleanHtmlTag = (html) => html.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")