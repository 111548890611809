import React, {useContext, useState} from "react"
import {Link, navigate} from "gatsby"
import classNames from "classnames"
import _ from "lodash"
import LogoImg from "../../images/Header/logo.png"
import LoginImg from "../../images/Header/login.svg"
import LogoutImg from "../../images/Header/logout.svg"
import UserImg from "../../images/Header/user.svg"
import CartImg from "../../images/Header/cart.svg"
import AuthContext from "../Context/AuthContext"
import CartContext from "../Context/CartContext"
import GoogleTranslateWidget from "../GoogleTranslate"

export default () => {
  const [modal, setModal] = useState(false)
  const {session} = useContext(AuthContext)
  const {cartInfo} = useContext(CartContext)

  const itemTotal = _.get(cartInfo, "ItemQtyTotal", 0)
  const logoutUrl = `${process.env.MODD_LOGOUT_URL}&ReturnUrl=${encodeURIComponent(process.env.MODD_LOGOUT_CONTINUE_URL)}`

  const modalLinkHandler = (path) => {
    setModal(false)
    navigate(path)
  }

  return (
    <header className={classNames({"modal-active": modal})}>
      <div className="content">
        <div id="header-logo">
          <Link to="/">
            <img src={LogoImg} alt=""/>
          </Link>
        </div>

        <GoogleTranslateWidget />

        <div id="header-menu">
          <div>
            {!_.isEmpty(session) && (
              <Link to="/account/" title="アカウント管理">
                <img src={UserImg} alt="User"/>
              </Link>
            )}
            {_.isEmpty(session) && (
              <Link to="/login/" title="ログイン">
                <img src={LoginImg} alt="Login"/>
              </Link>
            )}
            {!_.isEmpty(session) && (
              <a href={logoutUrl} title="ログアウト">
                <img src={LogoutImg} alt="Logout"/>
              </a>
            )}
            <Link to="/cart/" title="カート">
              <img src={CartImg} alt="Cart"/>
              {itemTotal > 0 && <span className="count">{itemTotal > 9 ? "9+" : itemTotal}</span>}
            </Link>
            <button onClick={() => setModal(!modal)}>
              <span/>
              <span/>
              <span/>
            </button>
          </div>
          <div>
            <Link to="/news/">ニュース一覧</Link>
            <Link to="/product/">商品一覧</Link>
            {!_.isEmpty(session) && (
              <Link to="/account/" title="アカウント管理">
                <img src={UserImg} alt="User"/>
                <span>アカウント管理</span>
              </Link>
            )}
            {_.isEmpty(session) && (
              <Link to="/login/" title="ログイン">
                <img src={LoginImg} alt="Login"/>
                <span>ログイン</span>
              </Link>
            )}
            {!_.isEmpty(session) && (
              <a href={logoutUrl} title="ログアウト">
                <img src={LogoutImg} alt="Logout"/>
                <span>ログアウト</span>
              </a>
            )}
            <Link to="/cart/" title="カート">
              <img src={CartImg} alt="Cart"/>
              <span>カート</span>
              {itemTotal > 0 && <span className="count">{itemTotal > 9 ? "9+" : itemTotal}</span>}
            </Link>
          </div>
        </div>
      </div>
      <div className="modal">
        <nav>
          <ul>
            {_.isEmpty(session) && (
              <li><button type="button" onClick={() => modalLinkHandler("/login/")}>ログイン・アカウント作成</button></li>
            )}
            <li><button type="button" onClick={() => modalLinkHandler("/news/")}>ニュース一覧</button></li>
            <li><button type="button" onClick={() => modalLinkHandler("/product/")}>商品一覧</button></li>
            <li><a href={`${process.env.MODD_CART_URL}help/help.aspx?ccode=${process.env.MODD_CART_ID}`}>ご利用ガイド</a></li>
            <li><a href={`https://inquiry.kerorofc.com/form/`}>お問い合わせ</a></li>
          </ul>
        </nav>
      </div>
    </header>
  )
}
